import Vue from "vue";
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/dist/locale/translations/es'
import moment from "moment";
import { iframeResize } from 'iframe-resizer';

Vue.component('multiselect', Multiselect);
Vue.component('datepicker', Datepicker);
moment.locale("es-co");

Vue.component('w1', {
    template: "#w1",
    data: function () {
        return {
            dplanguage: es,
            name: null,
            id: null,
            date: null,
            ids: [],
            height: 230,
            width: null
        };
    },
    beforeMount: function () {
        this.ids = quotes.all.map(q => {
            return {
                id: q.id,
                name: q.name
            };
        });
        this.$emit('height-changed', this.height);
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.name) {
                urlSearch.append("name", this.name);
            }
            if (this.id)
                urlSearch.append("id", this.id.id);
            if (this.date)
                urlSearch.append("date", moment(this.date).format('YYYY-MM-DD'));

            var ret = null;
            if (this.id)
                ret = document.location.origin + '/widget/w1?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w2', {
    template: "#w2",
    data: function () {
        return {
            dplanguage: es,
            name: null,
            id: null,
            from: null,
            to: null,
            interval: null,
            intervals: [],
            type: null,
            types: [],
            ids: [],
            height: 480,
            width: null
        };
    },
    beforeMount: function () {
        this.ids = quotes.all.map(q => {
            return {
                id: q.id,
                name: q.name
            };
        });
        this.intervals = Object.keys(config.intervals).map(q => {
            return {
                id: q,
                name: config.intervals[q]
            }
        });
        this.types = config.graphicTypes;
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        disableTo: function () {
            return this.from ? {
                to: this.from
            } : {};
        },
        disableFrom: function () {
            return this.to ? {
                from: this.to
            } : {};
        },
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.name) {
                urlSearch.append("name", this.name);
            }
            if (this.id)
                urlSearch.append("id", this.id.id);
            if (this.from)
                urlSearch.append("from", moment(this.from).format('YYYY-MM-DD'));
            if (this.to)
                urlSearch.append("to", moment(this.to).format('YYYY-MM-DD'));
            if (this.interval)
                urlSearch.append("interval", this.interval.id);
            if (this.type)
                urlSearch.append("type", this.type);

            var ret = null;
            if (this.id)
                ret = document.location.origin + '/widget/w2?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w3', {
    template: "#w1",
    data: function () {
        return {
            dplanguage: es,
            name: null,
            id: null,
            date: null,
            ids: [],
            height: 120,
            width: null
        };
    },
    beforeMount: function () {
        this.ids = quotes.bvc.map(q => {
            return {
                id: q.id,
                name: q.name
            };
        })
        this.$emit('height-changed', this.height);
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.name) {
                urlSearch.append("name", this.name);
            }
            if (this.id)
                urlSearch.append("id", this.id.id);
            if (this.date)
                urlSearch.append("date", moment(this.date).format('YYYY-MM-DD'));

            var ret = null;
            if (this.id)
                ret = document.location.origin + '/widget/w3?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w4', {
    template: "#w1",
    data: function () {
        return {
            dplanguage: es,
            name: null,
            id: null,
            date: null,
            ids: [],
            height: 215,
            width: null
        };
    },
    beforeMount: function () {
        this.ids = quotes.all.map(q => {
            return {
                id: q.id,
                name: q.name
            };
        });
        this.$emit('height-changed', this.height);
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.name) {
                urlSearch.append("name", this.name);
            }
            if (this.id)
                urlSearch.append("id", this.id.id);
            if (this.date)
                urlSearch.append("date", moment(this.date).format('YYYY-MM-DD'));

            var ret = null;
            if (this.id)
                ret = document.location.origin + '/widget/w4?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w5', {
    template: "#w5",
    data: function () {
        return {
            dplanguage: es,
            name: null,
            id: null,
            from: null,
            to: null,
            title: null,
            interval: null,
            intervals: [],
            type: null,
            types: [],
            ids: [],
            height: 480,
            width: null
        };
    },
    beforeMount: function () {
        this.ids = quotes.all.map(q => {
            return {
                id: q.id,
                name: q.name
            };
        });
        this.intervals = Object.keys(config.intervals).map(q => {
            return {
                id: q,
                name: config.intervals[q]
            }
        });
        this.types = config.graphicTypes;
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        disableTo: function () {
            return this.from ? {
                to: this.from
            } : {};
        },
        disableFrom: function () {
            return this.to ? {
                from: this.to
            } : {};
        },
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.name) {
                urlSearch.append("name", this.name);
            }
            if (this.id)
                urlSearch.append("id", this.id.id);
            if (this.from)
                urlSearch.append("from", moment(this.from).format('YYYY-MM-DD'));
            if (this.to)
                urlSearch.append("to", moment(this.to).format('YYYY-MM-DD'));
            if (this.title)
                urlSearch.append("title", this.title);
            if (this.interval)
                urlSearch.append("interval", this.interval.id);
            if (this.type)
                urlSearch.append("type", this.type);

            var ret = null;
            if (this.id)
                ret = document.location.origin + '/widget/w5?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w6', {
    template: "#w6",
    data: function () {
        return {
            dplanguage: es,
            title: null,
            id: null,
            from: null,
            to: null,
            interval: null,
            ids: [],
            types: [],
            intervals: [],
            sa: null,
            height: 480,
            width: null
        };
    },
    beforeMount: function () {
        this.ids = quotes.all.map(q => {
            return {
                id: q.id,
                name: q.name,
                type: 'line',
                displayName: null
            };
        })
        this.types = config.graphicTypes.map(q => {
            return q;
        });

        this.intervals = Object.keys(config.intervals).map(q => {
            return {
                id: q,
                name: config.intervals[q]
            }
        });
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        disableTo: function () {
            return this.from ? {
                to: this.from
            } : {};
        },
        disableFrom: function () {
            return this.to ? {
                from: this.to
            } : {};
        },
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.title)
                urlSearch.append("title", this.title);
            if (this.sa)
                urlSearch.append("sa", "true");
            if (this.to)
                urlSearch.append("to", moment(this.to).format('YYYY-MM-DD'));
            if (this.from)
                urlSearch.append("from", moment(this.from).format('YYYY-MM-DD'));
            if (this.interval)
                urlSearch.append("interval", this.interval.id);

            var ret = null;
            if (this.id) {
                this.id.forEach((q) => {
                    urlSearch.append("i", q.id);
                    urlSearch.append("n", q.displayName ? q.displayName : q.name);
                    urlSearch.append("t", q.type);
                });
                ret = document.location.origin + '/widget/w6?' + urlSearch.toString();
            }
            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w7', {
    template: "#w7",
    data: function () {
        return {
            dplanguage: es,
            name: null,
            id: null,
            from: null,
            to: null,
            interval: null,
            intervals: [],
            ids: [],
            height: 480,
            width: null
        };
    },
    beforeMount: function () {
        this.ids = quotes.all.map(q => {
            return {
                id: q.id,
                name: q.name
            };
        });
        this.intervals = Object.keys(config.intervals).map(q => {
            return {
                id: q,
                name: config.intervals[q]
            }
        });
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        disableTo: function () {
            return this.from ? {
                to: this.from
            } : {};
        },
        disableFrom: function () {
            return this.to ? {
                from: this.to
            } : {};
        },
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.name) {
                urlSearch.append("name", this.name);
            }
            if (this.id)
                urlSearch.append("id", this.id.id);
            if (this.from)
                urlSearch.append("from", moment(this.from).format('YYYY-MM-DD'));
            if (this.to)
                urlSearch.append("to", moment(this.to).format('YYYY-MM-DD'));
            if (this.interval)
                urlSearch.append("interval", this.interval.id);
            var ret = null;
            if (this.id)
                ret = document.location.origin + '/widget/w7?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w8', {
    template: "#w8",
    data: function () {
        return {
            dplanguage: es,
            title: null,
            id: null,
            from: null,
            to: null,
            interval: null,
            group: null,
            groups: [],
            intervals: [],
            ids: null,
            height: 480,
            width: null
        };
    },
    beforeMount: function () {
        this.groups = quotes.grouped.map(q => {
            return q;
        });

        this.intervals = Object.keys(config.intervals).map(q => {
            return {
                id: q,
                name: config.intervals[q]
            }
        });
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        },
        groupChange: function () {
            this.ids = null;
        }
    },
    computed: {
        disableTo: function () {
            return this.from ? {
                to: this.from
            } : {};
        },
        disableFrom: function () {
            return this.to ? {
                from: this.to
            } : {};
        },
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.title)
                urlSearch.append("title", this.title);
            if (this.group)
                urlSearch.append("groupname", this.group.groupName);
            if (this.to)
                urlSearch.append("to", moment(this.to).format('YYYY-MM-DD'));
            if (this.from)
                urlSearch.append("from", moment(this.from).format('YYYY-MM-DD'));
            if (this.interval)
                urlSearch.append("interval", this.interval.id);

            var ret = null;
            if (this.ids) {
                this.ids.forEach((q) => {
                    urlSearch.append("i", q.id);
                });
                ret = document.location.origin + '/widget/w8?' + urlSearch.toString();
            }
            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w9', {
    template: "#w9",
    data: function () {
        return {
            dplanguage: es,
            name: null,
            id: null,
            date: null,
            title: null,
            ids: [],
            height: 395,
            width: null
        };
    },
    beforeMount: function () {
        this.ids = Object.keys(config.buyAndSellOptions).map(q => {
            return {
                id: q,
                name: config.buyAndSellOptions[q]
            }
        });
        this.$emit('height-changed', this.height);
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        },
    },
    computed: {
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.id)
                urlSearch.append("id", this.id.id);
            if (this.date)
                urlSearch.append("date", moment(this.date).format('YYYY-MM-DD'));
            if (this.title)
                urlSearch.append("title", this.title);
            var ret = null;
            if (this.id)
                ret = document.location.origin + '/widget/w9?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w10', {
    template: "#w10",
    data: function () {
        return {
            dplanguage: es,
            date: null,
            title: null,
            height: 245,
            width: null
        };
    },
    beforeMount: function () {
        this.$emit('height-changed', this.height);
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.date)
                urlSearch.append("date", moment(this.date).format('YYYY-MM-DD'));
            if (this.title)
                urlSearch.append("title", this.title);

            var ret = document.location.origin + '/widget/w10?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w11', {
    template: "#w1",
    data: function () {
        return {
            dplanguage: es,
            name: null,
            id: null,
            date: null,
            ids: [],
            height: 190,
            width: null
        };
    },
    beforeMount: function () {
        this.ids = quotes.bvc.map(q => {
            return {
                id: q.id,
                name: q.name
            };
        })
        this.$emit('height-changed', this.height);
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.name) {
                urlSearch.append("name", this.name);
            }
            if (this.id)
                urlSearch.append("id", this.id.id);
            if (this.date)
                urlSearch.append("date", moment(this.date).format('YYYY-MM-DD'));

            var ret = null;
            if (this.id)
                ret = document.location.origin + '/widget/w11?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w12', {
    template: "#w2",
    data: function () {
        return {
            dplanguage: es,
            name: null,
            id: null,
            from: null,
            to: null,
            interval: null,
            intervals: [],
            type: null,
            types: [],
            ids: [],
            height: 480,
            width: null
        };
    },
    beforeMount: function () {
        this.ids = quotes.bvc.map(q => {
            return {
                id: q.id,
                name: q.name
            };
        });
        this.intervals = Object.keys(config.intervals).map(q => {
            return {
                id: q,
                name: config.intervals[q]
            }
        });
        this.types = config.graphicTypes;
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        disableTo: function () {
            return this.from ? {
                to: this.from
            } : {};
        },
        disableFrom: function () {
            return this.to ? {
                from: this.to
            } : {};
        },
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.name) {
                urlSearch.append("name", this.name);
            }
            if (this.id)
                urlSearch.append("id", this.id.id);
            if (this.from)
                urlSearch.append("from", moment(this.from).format('YYYY-MM-DD'));
            if (this.to)
                urlSearch.append("to", moment(this.to).format('YYYY-MM-DD'));
            if (this.interval)
                urlSearch.append("interval", this.interval.id);
            if (this.type)
                urlSearch.append("type", this.type);

            var ret = null;
            if (this.id)
                ret = document.location.origin + '/widget/w12?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w13', {
    template: "#w13",
    data: function () {
        return {
            dplanguage: es,
            nit: null,
            showDetail: false,
            height: 165,
            width: null
        };
    },
    beforeMount: function (params) {
        this.$emit('height-changed', this.height);
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        },
        changeSd: function () {
            this.height = this.showDetail ? 480 : 120;
            this.changeHeight();
        }
    },
    computed: {
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();

            if (this.nit)
                urlSearch.append("nit", this.nit);

            if (this.showDetail)
                urlSearch.append("sd", this.showDetail);

            var ret = null;
            if (this.nit)
                ret = document.location.origin + '/widget/w13?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w14', {
    template: "#w14",
    data: function () {
        return {
            dplanguage: es,
            date: null,
            bvcTableMode: null,
            height: 480,
            width: null,
            bvcTableModes: []
        };
    },
    beforeMount: function () {
        this.bvcTableModes = Object.keys(config.bvcTableMode).map(q => {
            return {
                id: q,
                name: config.bvcTableMode[q]
            }
        });
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.date)
                urlSearch.append("date", moment(this.date).format('YYYY-MM-DD'));

            if (this.bvcTableMode)
                urlSearch.append("bvcTableMode", this.bvcTableMode.id);

            var ret = document.location.origin + '/widget/w14?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w15', {
    template: "#w10",
    data: function () {
        return {
            dplanguage: es,
            date: null,
            title: 'Tasa de usura',
            height: 480,
            width: null
        };
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.date)
                urlSearch.append("date", moment(this.date).format('YYYY-MM-DD'));

            if (this.title)
                urlSearch.append("title", this.title);

            var ret = null;
            if (this.date)
                ret = document.location.origin + '/widget/w15?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});
Vue.component('w16', {
    template: "#w16",
    data: function () {
        return {
            dplanguage: es,
            name: null,
            id: null,
            date: null,
            type: null,
            types: [],
            ids: [],
            height: 480,
            width: null
        };
    },
    beforeMount: function () {
        this.ids = quotes.intraday.map(q => {
            return {
                id: q.id,
                name: q.name
            };
        });
        this.types = config.graphicTypes;
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.name) {
                urlSearch.append("name", this.name);
            }
            if (this.id)
                urlSearch.append("id", this.id.id);
            if (this.date)
                urlSearch.append("date", moment(this.date).format('YYYY-MM-DD'));
            if (this.type)
                urlSearch.append("type", this.type);

            var ret = null;
            if (this.id)
                ret = document.location.origin + '/widget/w16?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});


Vue.component('w17', {
    template: "#w17",
    data: function () {
        return {
            dplanguage: es,
            title: null,
            source: null,
            id: null,
            from: null,
            to: null,
            interval: null,
            ids: [],
            types: [],
            intervals: [],
            colors: [],
            sa: null,
            height: 900,
            width: 1080,
            color: null
        };
    },
    beforeMount: function () {
        this.ids = quotes.all.map(q => {
            return {
                id: q.id,
                name: q.name,
                type: 'line',
                displayName: null
            };
        })
        this.types = config.graphicTypes.map(q => {
            return q;
        });

        this.intervals = Object.keys(config.intervals).map(q => {
            return {
                id: q,
                name: config.intervals[q]
            }
        });
        this.colors = Object.keys(config.colors).map(q => {
            return {
                id: q,
                name: config.colors[q]
            }
        });
        this.updateSize();
    },
    methods: {
        updateSize: function () {
            this.$emit('width-changed', this.width);
            this.$emit('height-changed', this.height);
        },
    },
    computed: {
        disableTo: function () {
            return this.from ? {
                to: this.from
            } : {};
        },
        disableFrom: function () {
            return this.to ? {
                from: this.to
            } : {};
        },
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.title)
                urlSearch.append("title", this.title);
            if (this.source)
                urlSearch.append("source", this.source);
            if (this.sa)
                urlSearch.append("sa", "true");
            if (this.to)
                urlSearch.append("to", moment(this.to).format('YYYY-MM-DD'));
            if (this.from)
                urlSearch.append("from", moment(this.from).format('YYYY-MM-DD'));
            if (this.interval)
                urlSearch.append("interval", this.interval.id);
            if (this.color && this.color.id != '#ffffff')
                urlSearch.append("color", this.color.id);

            var ret = null;
            if (this.id) {
                this.id.forEach((q) => {
                    urlSearch.append("i", q.id);
                    urlSearch.append("n", q.displayName ? q.displayName : q.name);
                    urlSearch.append("t", q.type);
                });
                ret = document.location.origin + '/widget/w17?' + urlSearch.toString();
            }
            this.$emit('src-changed', ret);
            return ret;
        }
    }
});

Vue.component('covid19', {
    template: "#covid19",
    data: function () {
        return {
            height: null,
            width: null
        };
    },
    mounted: function () {
        this.$emit('height-changed', this.height);
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        iFrameSrc: function () {
            var ret = document.location.origin + '/widget/covid19';
            this.$emit('src-changed', ret);
            return ret;
        }
    }
});

Vue.component('covid19vaccination', {
    template: "#covid19vaccination",
    data: function () {
        return {
            height: null,
            width: null
        };
    },
    mounted: function () {
        this.$emit('height-changed', this.height);
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        iFrameSrc: function () {
            var ret = document.location.origin + '/widget/covid19vaccination';
            this.$emit('src-changed', ret);
            return ret;
        }
    }
});

Vue.component('covid19StateComparator', {
    template: "#covid19-state-comparator",
    data: function () {
        return {
            height: null,
            width: null,
            title: 'EVOLUCIÓN DE LOS CASOS'
        };
    },
    mounted: function () {
        this.$emit('height-changed', this.height);
    },
    methods: {
        changeHeight: function () {
            this.$emit('height-changed', this.height);
        }
    },
    computed: {
        iFrameSrc: function () {
            var urlSearch = new URLSearchParams();
            if (this.title)
                urlSearch.append("title", this.title);

            var ret = document.location.origin + '/widget/covid19-state-comparator?' + urlSearch.toString();

            this.$emit('src-changed', ret);
            return ret;
        }
    }
});

var home = {
    name: 'home',
    hasInit: false,
    vueInstance: {},
    init: function () {
        var _self = this;
        this.vueInstance = new Vue({
            el: "#home-vue",
            data: function () {
                return {
                    widgets: [],
                    selectedWidget: null,
                    iframeSrc: null,
                    height: 480,
                    width: '100%',
                    completeIframe: null,
                    fullscreen: false
                };
            },
            beforeMount: function () {
                this.widgets = Object.keys(config.widgets).map(q => {
                    return {
                        id: q,
                        name: config.widgets[q]
                    }
                });
            },
            updated: function () {
                if (this.height) {
                    this.completeIframe = '<iframe height="' + this.height + '" width="' + this.width + '" src="' + this.iframeSrc + '" frameborder="0"></iframe>';
                } else {
                    this.completeIframe = '<iframe width="' + this.width + '" src="' + this.iframeSrc + '" frameborder="0"></iframe>';
                }
                iframeResize(null, 'iframe')
            },
            computed: {},
            methods: {
                changeWidget: function () {
                    this.height = 480;
                    this.width = '100%';
                    this.iframeSrc = null;
                    this.completeIframe = null;
                },
                copy: function () {
                    const el = document.createElement('textarea');
                    el.value = this.completeIframe;
                    el.setAttribute('readonly', '');
                    el.style.position = 'absolute';
                    el.style.left = '-9999px';
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);
                }
            }
        });
    }

};

home.init();